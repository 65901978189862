<template>
  <div class="bg-black text-white pb-5 my-5">
    <div class="container">
      <h2 class="fw-bold xl-fs-f-3-75rem md-fs-f-2-5rem pt-5">
        {{ $t("common_qa1") }}
      </h2>
      <div class="w-md-100 mx-lg-auto lg-mt-8 mt-5">
        <div>
          <h3 class="fw-bold fs-f-2-5rem md-fs-f-1-875rem">
            {{ $t("common_qa2") }}
          </h3>
          <ul class="mt-5 c-pointer p-0">
            <li
              class="p-4 border-bottom qa-hover"
              data-bs-toggle="collapse"
              @click="if_qa_visible"
              v-for="l in 9"
              :key="l"
              :class="l == 1 ? 'pt-0' : 'pt-5'"
              :data-bs-target="'#q' + l"
            >
              <div class="d-flex justify-content-between">
                <span class="text-left fw-bold fs-f-125 qa-title-hover">{{
                  this.$t("vq" + l + "_t")
                }}</span>
                <div class="">
                  <svg
                    viewBox="0 0 20 20"
                    class="w-44px h-44px my-bg-white text-black rounded-circle qa-svg-hover"
                  >
                    <path
                      d="M10 4a1 1 0 0 0-1 1v4H5a1 1 0 1 0 0 2h4v4a1 1 0 1 0 2 0v-4h4a1 1 0 1 0 0-2h-4V5a1 1 0 0 0-1-1Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  <svg
                    viewBox="0 0 20 20"
                    class="w-44px h-44px my-bg-black my-text-white rounded-circle qa-svg2-hover boder-white border-2 border-style d-none"
                  >
                    <path
                      d="M14.1667 9H5.83333C5.3725 9 5 9.448 5 10C5 10.552 5.3725 11 5.83333 11H14.1667C14.6275 11 15 10.552 15 10C15 9.448 14.6275 9 14.1667 9"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
              </div>

              <div
                class="collapse fs-f-125 w-80"
                :id="'q' + l"
                v-html="$t('vq' + l + '_c')"
              ></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "commonqa",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  components: {},
  computed: {},
  methods: {
    if_qa_visible(event) {
      if (
        event.currentTarget.getAttribute("aria-expanded").toLowerCase() ==
        "true"
      ) {
        event.currentTarget
          .getElementsByClassName("qa-svg-hover")[0]
          .classList.remove("d-block");
        event.currentTarget
          .getElementsByClassName("qa-svg-hover")[0]
          .classList.add("d-none");
        event.currentTarget
          .getElementsByClassName("qa-svg2-hover")[0]
          .classList.remove("d-none");
        event.currentTarget
          .getElementsByClassName("qa-svg2-hover")[0]
          .classList.add("d-block");
      } else {
        event.currentTarget
          .getElementsByClassName("qa-svg-hover")[0]
          .classList.remove("d-none");
        event.currentTarget
          .getElementsByClassName("qa-svg-hover")[0]
          .classList.add("d-block");
        event.currentTarget
          .getElementsByClassName("qa-svg2-hover")[0]
          .classList.remove("d-block");
        event.currentTarget
          .getElementsByClassName("qa-svg2-hover")[0]
          .classList.add("d-none");
      }
    },
  },
  watch: {},
};
</script>
