<template>
  <div class="wc-PageView wc_myactivities rent_div">
    <div
      class="wc-PageView_ContentContainer wc-ModulePageChangePageData_ContentContainer"
    >
      <div>
        <div class="nh-NavigationHeaderModule nh-NavigationHeaderModule-wide">
          <div class="nh-NavigationHeaderModule_Title">
            {{ $t("rent_text1") }}
          </div>
        </div>
      </div>
      <div style="padding-left: 15px">
        <span
          class="c-pointer"
          @click="is_not_id = 'N'"
          :class="is_not_id == 'N' ? 'bg_light_coff2' : ''"
          style="font-size: 18px"
          >{{ $t("rent_text2") }}</span
        >
        <span class="">｜</span>
        <span
          class="c-pointer"
          @click="is_not_id = 'Y'"
          :class="is_not_id == 'Y' ? 'bg_light_coff2' : ''"
          style="font-size: 18px"
          >{{ $t("rent_text3") }}</span
        >
      </div>

      <div>
        <header class="sticky-header">
          <div class="sticky-header-inner">
            <div class="nav-wrapper">
              <ul class="nav">
                <li
                  class="nav-item"
                  :class="this.day == 1 ? 'nav-item-selected first' : ''"
                  @click="this.day = 1"
                >
                  <div>
                    <span>{{ $t("rent_text4") }}</span>
                  </div>
                </li>

                <li
                  class="nav-item"
                  :class="this.day == 7 ? 'nav-item-selected first' : ''"
                  @click="this.day = 7"
                >
                  <div>
                    <span>{{ $t("week") }}</span>
                  </div>
                </li>

                <li
                  class="nav-item"
                  :class="this.day == 30 ? 'nav-item-selected first' : ''"
                  @click="this.day = 30"
                >
                  <div>
                    <a>{{ $t("activity_30") }}</a>
                  </div>
                </li>

                <li
                  class="nav-item last"
                  :class="this.day == 365 ? 'nav-item-selected first' : ''"
                  @click="this.day = 365"
                >
                  <div>
                    <a>{{ $t("rent_text5") }}</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </header>
        <div class="container">
          <div class="panel-container" v-show="is_not_id == 'N'">
            <section class="panel">
              <header class="panel-header panel-header__fullheight">
                <div class="panel-title header-tool-tip-container">
                  <h1>{{ $t("rent_text6") }}</h1>
                </div>

                <h2 class="panel-subtitle">
                  {{ $t("rent_text7") }}
                </h2>
                <div class="panel-summary">
                  <div class="amount" style="color: #00af4c">
                    {{ $t("cur") }} {{ payment }}
                  </div>

                  <div class="time-period">{{ begin }}-{{ end }}</div>
                </div>
              </header>
            </section>
            <section class="panel">
              <header class="panel-header panel-header__fullheight">
                <div class="panel-title header-tool-tip-container">
                  <h1>{{ $t("rent_text8") }}</h1>
                </div>
                <div class="panel-summary">
                  <div class="amount" style="color: #00af4c">
                    {{ $t("cur") }} {{ buy }}
                  </div>

                  <div class="time-period">{{ begin }}-{{ end }}</div>
                </div>
              </header>
            </section>
          </div>
          <div class="panel-container" v-show="is_not_id == 'Y'">
            <section class="panel">
              <header class="panel-header panel-header__fullheight">
                <div class="panel-title header-tool-tip-container">
                  <h1>{{ $t("rent_text9") }}</h1>
                </div>

                <h2 class="panel-subtitle">
                  {{ $t("rent_text10") }}
                </h2>
                <div class="panel-summary">
                  <div class="amount bg_light_coff2">
                    {{ count }} {{ $t("rent_text13") }}
                  </div>

                  <div class="time-period">{{ begin }}-{{ end }}</div>
                </div>
              </header>
            </section>
            <section class="panel">
              <header class="panel-header panel-header__fullheight">
                <div class="panel-title header-tool-tip-container">
                  <h1>{{ $t("rent_text11") }}</h1>
                </div>
                <div class="panel-title header-tool-tip-container px-3">
                  <div
                    class="d-flex justify-content-between align-items-center mb-2"
                    v-for="(l, k) in list.slice(baginVal, endval)"
                    :key="k"
                  >
                    <span>{{ k + 1 }} - {{ l.userid }}</span>
                    <span
                      class="bg_light_coff2 c-pointer"
                      @click="more(l.userid)"
                    >
                      {{ $t("rent_text12") }}</span
                    >
                  </div>
                  <div class="mt-5 text-center">
                    <span v-for="l in page_total" :key="l" @click="who_page(l)">
                      <span
                        class="c-pointer"
                        :class="l == page_index ? 'bg_light_coff2' : ''"
                        :style="
                          l == page_index
                            ? 'border-bottom:1px solid yellow'
                            : ''
                        "
                        >{{ l }}
                      </span>
                      <span v-if="l != page_total"> / </span>
                    </span>
                  </div>
                </div>
              </header>
            </section>
          </div>
        </div>
      </div>
    </div>
    <div class="finance_page home_vip_page" v-show="flag_finance_page">
      <div class="finance_card">
        <div class="finance_close" @click="flag_finance_page = false">×</div>
        <div class="finance_title">
          {{ $t("rent_text12") }}
        </div>

        <div class="finance_main">
          <div class="finance_deposit" style="margin-top: 10px">
            <div class="mt-4">
              <p class="mb-0">{{ $t("rent_text14") }}：{{ userid }}</p>
            </div>

            <div class="mt-4">
              <p class="mb-0">{{ $t("rent_text15") }}：{{ opdate }}</p>
            </div>

            <div class="mt-4">
              <p class="mb-0">{{ $t("rent_text16") }}：{{ losewin }}</p>
            </div>

            <div class="mt-4">
              <p class="mb-0">{{ $t("rent_text17") }}：{{ turnover }}</p>
            </div>

            <div class="mt-4 mb-4">
              <p class="mb-0">{{ $t("rent_text18") }}：{{ buy }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import dateTime from "@/components/utils/datatime";

export default {
  name: "Home",
  computed: {},
  data() {
    return {
      flag_finance_page: false,
      day: 1,
      begin: dateTime.getoneday(0),
      end: dateTime.getoneday(0),
      is_not_id: "N",
      baginVal: 0,
      endval: 0,
      page_total: 1,
      page_index: 1,
      list: [],
      buy: 0,
      payment: 0,
      count: 0,
      userid: "",
      opdate: "",
      turnover: 0,
      losewin: 0,
    };
  },
  watch: {
    day: function (e) {
      switch (e) {
        case 1:
          this.begin = dateTime.getoneday(0);
          this.end = dateTime.getoneday(0);
          break;
        case 7:
          this.begin = dateTime.getoneday(-7);
          this.end = dateTime.getoneday(0);
          break;
        case 30:
          this.begin = dateTime.getoneday(-30);
          this.end = dateTime.getoneday(0);
          break;
        case 365: //這裡是上個月，不是一年
          this.begin = dateTime.getLastMonthStartDate();
          this.end = dateTime.getLastMonthEndDate();
          break;
      }
      this.getdata();
    },
    is_not_id: function () {
      this.getdata();
    },
  },
  methods: {
    getdata: function () {
      if (this.is_not_id == "N") {
        let param = {
          action: "club_comm",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
            begindate: this.begin,
            enddate: this.end,
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          this.buy = this.filter_number(res.buy);
          this.payment = this.filter_number(res.payment);
        });
      } else {
        let param = {
          action: "club_member",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          this.count = res.count;
          this.list = res.data;
          this.page_total =
            this.list.length % 5 == 0
              ? this.list.length / 5
              : Math.floor(this.list.length / 5) + 1;

          this.who_page(this.page_index);
        });
      }
    },
    more: function (e) {
      let param = {
        action: "club_member_data",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          userid: e,
          begindate: this.begin,
          enddate: this.end,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        if (res.status == "success") {
          this.userid = res.userid;
          this.opdate = res.opdate;
          this.turnover = res.turnover;
          this.losewin = res.losewin;
          this.buy = res.buy;
          this.flag_finance_page = true;
        } else this.$MSG.API_popmsg(res.msg, "", "error");
      });
    },
    filter_number: function (value) {
      if (!value) return 0;
      const valueArray = value.toString().split(".");
      let regex = /(\d)(?=(?:\d{3})+$)/g;
      if (valueArray.length === 2) {
        const intPart = valueArray[0].toString().replace(regex, "$1,");
        let floatPart = "";
        floatPart = valueArray[1].toString();
        return intPart + "." + floatPart;
      } else {
        const intPart = value.toString().replace(regex, "$1,");
        return intPart;
      }
    },
    who_page: function (page_id) {
      this.page_index = page_id;

      this.baginVal =
        this.list.length > 5 * (this.page_index - 1)
          ? 5 * (this.page_index - 1)
          : this.list.length;

      this.endval =
        this.list.length > 5 * (this.page_index - 1) + 5
          ? 5 * (this.page_index - 1) + 5
          : this.list.length;
    },
  },
  created() {
    this.getdata();
  },
};
</script>
